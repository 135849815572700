import React from 'react';
import {Header} from "./components/Header/Header";
import {Banner} from "./components/Banner/Banner";
import {About} from "./components/About/About";
import {Gallery} from "./components/Gallery/Gallery";
import {Services} from "./components/Services/Services";
import {Advantages} from "./components/Advantages/Advantages";
import {Footer} from "./components/Footer/Footer";
import {Map} from "./components/Map/Map";
import {Projects} from "./components/Projects/Projects";

function App() {
    return (
        <>
            <Header/>
            <Banner/>
            <Projects/>
            <Services/>
            <Advantages/>
            <About/>
            <Gallery/>
            <Map/>
            <Footer/>
        </>
    );
}

export default App;
