import './About.scss';
import {useTranslation} from "react-i18next";
import aboutImg from '../../assets/images/bulding-2.jpg';
import aboutImgTwo from '../../assets/images/bulding-3.jpg';
import {useRef} from "react";
import useOnScreen from "../../hooks/useOnScreen";
export const About = () => {
    const {t} = useTranslation()
    const elementRef = useRef<HTMLDivElement>(null)
    const element = useOnScreen(elementRef)
    return <section className={'about-sec'} id={'about'}>
        <div className="G-container">
            <h2>{t('about-title')}</h2>
            <div  ref={elementRef} className={`about-main G-animation ${element ? 'G-show-element' : ''}`}>
                <div className={'about-cnt G-align-center G-justify-between'}>
                    <div className={'about-info'}>
                        <p>{t('about-text')}</p>
                    </div>
                    <div className={'about-image'}>
                        <img src={aboutImg} alt="about"/>
                    </div>
                </div>
                <div className={'about-cnt  G-align-center G-justify-between G-row'}>
                    <div className={'about-info'}>
                        <p>{t('about-desc')}</p>
                    </div>
                    <div className={'about-image'}>
                        <img src={aboutImgTwo} alt="about"/>
                    </div>
                </div>
            </div>
        </div>
    </section>
}
