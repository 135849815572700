import './Advantages.scss';
import {useTranslation} from "react-i18next";
import {useRef} from "react";
import useOnScreen from "../../hooks/useOnScreen";
export const Advantages = () => {
    const {t} = useTranslation()

    const elementRef = useRef<HTMLDivElement>(null)
    const element = useOnScreen(elementRef)
    return <section className={'advantages-sec'} id={'advantages'}>
        <div className={'G-container'}>
            <h2>{t('title-advantages')}</h2>
            <div  ref={elementRef} className={`services-cnt G-flex-wrap G-justify-center G-animation ${element ? 'G-show-element' : ''}`}>
                <div className={'services-box'}>
                    <h3>{t('advantages-name')}</h3>
                    <p>{t('advantages-text')}</p>
                </div>
                <div className={'services-box'}>
                    <h3>{t('advantages-tow-name')}</h3>
                    <p>{t('advantages-tow-text')}</p>
                </div>
                <div className={'services-box'}>
                    <h3>{t('advantages-three-name')}</h3>
                    <p>{t('advantages-three-text')}</p>
                </div>
                <div className={'services-box'}>
                    <h3>{t('advantages-four-name')}</h3>
                    <p>{t('advantages-four-text')}</p>
                </div>
                <div className={'services-box'}>
                    <h3>{t('advantages-five-name')}</h3>
                    <p>{t('advantages-five-text')}</p>
                </div>
                <div className={'services-box'}>
                    <h3>{t('advantages-six-name')}</h3>
                    <p>{t('advantages-six-text')}</p>
                </div>

            </div>
        </div>
    </section>
}
