import './Services.scss';
import {useTranslation} from "react-i18next";
import {useRef} from "react";
import useOnScreen from "../../hooks/useOnScreen";

export const Services = () => {
    const {t} = useTranslation()
    const elementRef = useRef<HTMLDivElement>(null)
    const element = useOnScreen(elementRef)
    return <section className={'services-sec'} id={'services'}>
        <div className={'G-container'}>
            <h2>{t('title-services')}</h2>
            <div ref={elementRef}
                 className={`services-cnt G-flex-wrap G-justify-center G-animation ${element ? 'G-show-element' : ''}`}>
                <div className={'services-box'}>
                    <h3>{t('services-name')}</h3>
                    <p>{t('services-text')}</p>
                </div>
                <div className={'services-box'}>
                    <h3>{t('services-tow-name')}</h3>
                    <p>{t('services-tow-text')}</p>
                </div>
                <div className={'services-box'}>
                    <h3>{t('services-three-name')}</h3>
                    <p>{t('services-three-text')}</p>
                </div>

            </div>
        </div>
    </section>
}
