import React, {useRef} from "react";
import './Projects.scss'
import {useTranslation} from "react-i18next";

import project_6 from '../../assets/images/bulding-2.jpg';
import project_1 from '../../assets/images/bulding.jpg';
import project_2 from '../../assets/images/project_1.jpg';
import project_3 from '../../assets/images/project_2.jpg';
import project_4 from '../../assets/images/project_3.jpg';
import project_5 from '../../assets/images/project_4.jpg';
import {Swiper, SwiperSlide} from "swiper/react";
import {EffectFade, Navigation} from "swiper/modules";
import 'swiper/css';
import 'swiper/css/effect-fade';
import {IconArrowLeft} from "../../assets/icons/IconArrowLeft";
import {IconArrowRight} from "../../assets/icons/IconArrowRight";
import useOnScreen from "../../hooks/useOnScreen";

interface IProject {
    images: string[],
    title: string,
    date: string,
}

export const Projects = () => {
    const {t} = useTranslation()
    const elementRef = useRef<HTMLDivElement>(null)
    const element = useOnScreen(elementRef)

    const projectsList = [
        {
            images: [project_1, project_2, project_3],
            title: 'title 1',
            date: '05/05/2024',
        },
        {
            images: [project_4, project_5, project_6],
            title: 'title 2',
            date: '05/05/2024',
        },
        {
            images: [project_1, project_2, project_3],
            title: 'title 3',
            date: '05/05/2024',
        },
        {
            images: [project_4, project_5, project_6],
            title: 'title 4',
            date: '05/05/2024',
        },
        {
            images: [project_1, project_2, project_3],
            title: 'title 5',
            date: '05/05/2024',
        },
        {
            images: [project_4, project_5, project_6],
            title: 'title 6',
            date: '05/05/2024',
        },
    ]

    return <section id={'projects'}>
        <div className="G-container">
            <h2 className={'project-title'}>{t('projects-title')}</h2>
            <div  ref={elementRef} className={`projects-list G-animation ${element ? 'G-show-element' : ''}`}>
                {projectsList.map((item, index)=> <ProjectItem key={index} data={item}/>)}

            </div>
        </div>
    </section>
}



const ProjectItem = ({data}: {data: IProject})=>{

    const settings = {
        slidesPerView: 1,
        spaceBetween: 0,
        effect: 'fade',
        loop: true,
        speed:500,
        navigation:{
            nextEl:'.swiper-button-next',
            prevEl:'.swiper-button-prev',
        },
        modules:[EffectFade, Navigation]
    }

    return <div className={'project-item'}>
        <div className={'project-img-slider'}>
            <Swiper {...settings}>
                {data.images.map((img, i) => <SwiperSlide key={i}><img className={'project-img'} src={img} alt=""/></SwiperSlide>)}
                <button className={'swiper-arrows-item G-center swiper-button-prev'}><IconArrowLeft size={20}/></button>
                <button className={'swiper-arrows-item G-center swiper-button-next'}><IconArrowRight size={20}/></button>
            </Swiper>
        </div>
        <div className={'project-info G-align-center G-justify-between'}>
            <h3>{data.title}</h3>
            <p>{data.date}</p>
        </div>
    </div>
}
