import './Gallery.scss';
import {useTranslation} from "react-i18next";
import React, {useRef} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import project_6 from '../../assets/images/bulding-2.jpg';
import project_1 from '../../assets/images/bulding.jpg';
import project_2 from '../../assets/images/project_1.jpg';
import project_3 from '../../assets/images/project_2.jpg';
import project_4 from '../../assets/images/project_3.jpg';
import project_5 from '../../assets/images/project_4.jpg';

import 'swiper/css';
import 'swiper/css/effect-coverflow';

import {EffectCoverflow} from 'swiper/modules';
import useOnScreen from "../../hooks/useOnScreen";

export const Gallery = () => {
    const {t} = useTranslation()
    const elementRef = useRef<HTMLDivElement>(null)
    const element = useOnScreen(elementRef)

    const images = [project_1, project_2, project_3, project_4, project_5, project_6]

    const settings  = {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 4,
        loop: true,
        coverflowEffect: {
            rotate: 25,
            stretch: 1,
            depth: 100,
            modifier: 1,
            slideShadows: true,
        },
        breakpoints: {
            320: {
                slidesPerView: 2,
            },
            576: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 3,
            },
            1200: {
                slidesPerView: 4,
            },
        },
        modules: [EffectCoverflow],
    }
    return <section className={'projects-sec'} >
        <div className="G-container">
            <h2 className={'projects-title'}>{t('gallery-title')}</h2>
        </div>
        <div ref={elementRef} className={`projects-cnt G-animation ${element ? 'G-show-element' : ''}`}>
            <Swiper {...settings}  >
                {images.map((img, i) => <SwiperSlide key={i}><img src={img} alt=""/></SwiperSlide>)}
            </Swiper>
        </div>
    </section>
}
