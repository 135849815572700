import './Banner.scss';
import bannerFon from '../../assets/images/bulding.jpg';
import {useTranslation} from "react-i18next";
import {useRef} from "react";
import useOnScreen from "../../hooks/useOnScreen";

export const Banner = () => {
    const {t} = useTranslation()
    const titleRef = useRef<HTMLHeadingElement>(null)
    const descriptionRefRef = useRef<HTMLHeadingElement>(null)
    const isVisibleTitle = useOnScreen(titleRef)
    const isVisibleDescription = useOnScreen(titleRef)

    return <section className={'banner-sec'} style={{backgroundImage: `url('${bannerFon}')`}}>
        <div className="G-container">
            <div className="banner-main">
                <h1 ref={titleRef} className={`G-animation ${isVisibleTitle ? 'G-show-element' : ''}`}>{t('banner-title')}</h1>
                <p ref={descriptionRefRef}  className={`G-animation ${isVisibleDescription ? 'G-show-element' : ''}`}>{t('banner-text')}</p>
            </div>
        </div>
    </section>
}
