import './Map.scss';
import {useRef} from "react";
import useOnScreen from "../../hooks/useOnScreen";

export const Map = () => {

    const elementRef = useRef<HTMLDivElement>(null)
    const element = useOnScreen(elementRef)
    return <section className={'map-sec'}>
        <div  ref={elementRef} className={`map G-animation ${element ? 'G-show-element' : ''}`}>
            <iframe title={'baghl'}
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d598.3022513469951!2d44.57094577262837!3d40.199996223824286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDDCsDEyJzAwLjEiTiA0NMKwMzQnMTUuNCJF!5e0!3m2!1sen!2sam!4v1726230027663!5m2!1sen!2sam"
              allowFullScreen loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </section>
}
