import './Footer.scss';
import {useTranslation} from "react-i18next";
import footerLogo from '../../assets/images/logo.svg'
import iconCall from '../../assets/images/call.svg';
import iconMail from '../../assets/images/mail.svg';

export const Footer = () => {
    const {t} = useTranslation()
    return <footer>
        <div className={'G-container'}>
            <div className={'footer-main G-align-center G-justify-between'}>
                <a href="#" className={'footer-logo'}>
                    <img src={footerLogo} alt="logo"/>
                </a>
                <div className={'footer-contact G-align-center'}>
                    <a href="mailto:bulding@gmail.com" >
                        <img src={iconMail} alt="mail"/>
                        <span>bulding@gmail.com</span>
                    </a>
                    <a href="tel:+37496588888">
                        <img src={iconCall} alt="icon"/>
                        <span>+374 96 58 88 88</span>
                    </a>
                    <a href="tel:010636500">
                        <img src={iconCall} alt="icon"/>
                        <span>010 63 65 00</span>
                    </a>
                </div>
            </div>
            <div className={'footer-down G-justify-center'}>
                <p>{t('footer-text')}</p>
            </div>
        </div>
    </footer>
}
