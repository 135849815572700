import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'

import en_translation from './locales/en/translations.json'
import ru_translation from './locales/ru/translations.json'
import hy_translation from './locales/hy/translations.json'
import {LanguageEnum} from "../enums/language.enum";

const DEFAULT_LOCALE: string = LanguageEnum[LanguageEnum.armenian];

i18n.use(initReactI18next).init({
    // debug: global.isDev,
    debug: false,
    resources: {
        en: {translation: en_translation},
        ru: {translation: ru_translation},
        hy: {translation: hy_translation},
    },
    lng: DEFAULT_LOCALE,
    fallbackLng: 'en',
    keySeparator: false,
    interpolation: {
        escapeValue: false,
    },
}).then()

export default i18n
